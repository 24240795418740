import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chat-authorship-field',
  templateUrl: './chat-authorship-field.component.html',
  styleUrls: ['./chat-authorship-field.component.css']
})
export class ChatAuthorshipFieldComponent implements OnInit {

  @Input() provider: any = null;
  @Input() url: any = null;

  constructor() { }

  ngOnInit(): void {}
}
