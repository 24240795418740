import { Component, ElementRef, AfterViewChecked, ViewChild, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  @Input() messages: any[] = [];
  parsedMessages: any[] = [];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.parseMessages();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch(err) {
      console.error('Scroll to bottom error:', err);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this.parseMessages();
    }
  }

  parseMessages(): void {
    if (this.messages) {
      this.parsedMessages = this.messages.map(message => {
        return {
          ...message,
          content: this.sanitizer.bypassSecurityTrustHtml(this.formatContent(message.content))
        };
      });
      console.log('Parsed Messages:', this.parsedMessages);
    }
  }

  formatContent(content: string): string {
    return content.replace(/\n/g, '<br>');
  }
}
