import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { GoogleFieldComponent } from './google-field/google-field.component';
// import { IframeComponent } from './iframe/iframe.component';
// import { ResultsComponent } from './results/results.component';
// import { SortSearchComponent } from './sort-search/sort-search.component';
// import { BoxComponent } from './box/box.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
// import { SummaryComponent } from './summary/summary.component';
import { ChatProfileBoxComponent } from './chat-profile-box/chat-profile-box.component';
// import { PaginationComponent } from './pagination/pagination.component';
// import { FacetComponent } from './facet/facet.component';
// import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
// import { InfoComponent } from './info/info.component';
// import { FacetNavComponent } from './facet-nav/facet-nav.component';
// import { ResultCardComponent } from './result-card/result-card.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { ActionsComponent } from './actions/actions.component';
// import { ActionFieldSelectorComponent } from './action-field-selector/action-field-selector.component';
// import { ResetComponent } from './reset/reset.component';
// import { NumberFieldComponent } from './number-field/number-field.component';
// import { CurrencyFieldComponent } from './currency-field/currency-field.component';
import { SpyglassChatComponent } from './spyglass-chat/spyglass-chat.component';
// import { DateFieldComponent } from './date-field/date-field.component';
// import { LinkFieldComponent } from './link-field/link-field.component';
// import { CustomFieldComponent } from './custom-field/custom-field.component';
import { CustomFieldDirective } from './custom-field.directive';
// import { JsonFieldComponent } from './json-field/json-field.component';
// import { SwirlMixerFilterComponent } from './swirl-mixer-filter/swirl-mixer-filter.component';
// import { TitleFieldComponent } from './title-field/title-field.component';
// import { AuthorshipFieldComponent } from './authorship-field/authorship-field.component';
// import { BodyFieldComponent } from './body-field/body-field.component';
// import { LayoutToggleComponent } from './layout-toggle/layout-toggle.component';
// import { StringFieldComponent } from './string-field/string-field.component';
import { ChatComponent } from './chat/chat.component'
import { ChatAuthorshipFieldComponent } from './chat-authorship-field/chat-authorship-field.component'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AccordionModule } from 'primeng/accordion';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { ConfigService } from './config.service';
import { MsalAccessService } from '../auth/msal-service'
import { OauthService } from '../auth/oauth2-service'
import { WebSocketService } from './websockets.service'
import { timer } from 'rxjs';
import { switchMap, filter, take, first, tap } from 'rxjs/operators';
import { ConfigResponse } from '../spyglass-search/shared/config-response';
import { AuthInterceptorService } from '../auth/auth-interceptor.service'; // Update the path as necessary
// import { GalaxyChart } from './chart/chart.component';
import { CookieService } from 'ngx-cookie-service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


export function initializeApp(configService: ConfigService, msalService: MsalAccessService, oauthService: OauthService, webSocketService: WebSocketService) {
  return () => {
    const pollingInterval = 1000; // Interval between polling attempts (in milliseconds)

    return timer(0, pollingInterval)
      .pipe(
        tap(() => console.log('Polling for config...')),
        switchMap(() => configService.getConfig('default')),
        tap((configResponse) => console.log('Config response:', configResponse)),
        filter((configResponse): configResponse is ConfigResponse => configResponse !== null && configResponse.msalConfig !== null),
        take(1),
        first() // Ensures that the Observable completes after the first value
      )
      .toPromise()
      .then((configResponse) => {
        const oauthConf = configResponse.oauthConfig;
        const msalConf = configResponse.msalConfig;
        const shouldUseTokenFromOauth = configResponse.shouldUseTokenFromOauth;
        const webSocketConf = configResponse.webSocketConfig;
        const microsoftGalaxyOauth = configResponse.microsoftGalaxyOauth;
        if (oauthConf)
          oauthService.loadConfig(oauthConf)
        if (webSocketConf)
          webSocketService.loadConfig(webSocketConf)
        msalService.loadConfig(msalConf, shouldUseTokenFromOauth, microsoftGalaxyOauth);
      });
  };
}


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  autoOpen: false,
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#0060a8",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#ffffff",
      text: "#000000",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://swirlaiconnect.com/privacy-policy"
  }
};

@NgModule({
  declarations: [
    // GoogleFieldComponent,
    // IframeComponent,
    // ResultsComponent,
    // BoxComponent,
    ChatBoxComponent,
    ChatProfileBoxComponent,
    // SummaryComponent,
    // PaginationComponent,
    // FacetComponent,
    // BreadcrumbComponent,
    // InfoComponent,
    // FacetNavComponent,
    // ResultCardComponent,
    // ActionsComponent,
    // SortSearchComponent,
    // ActionFieldSelectorComponent,
    // ResetComponent,
    // NumberFieldComponent,
    // CurrencyFieldComponent,
    SpyglassChatComponent,
    // DateFieldComponent,
    // LinkFieldComponent,
    // CustomFieldComponent,
    CustomFieldDirective,
    // JsonFieldComponent,
    // SwirlMixerFilterComponent,
    // TitleFieldComponent,
    // AuthorshipFieldComponent,
    // BodyFieldComponent,
    // LayoutToggleComponent,
    // StringFieldComponent,
    // GalaxyChart,
    ChatComponent,
    ChatAuthorshipFieldComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    AccordionModule,
    ButtonModule
  ],
  exports: [
    // GoogleFieldComponent,
    // IframeComponent,
    // ResultsComponent,
    // BoxComponent,
    ChatProfileBoxComponent,
    // SummaryComponent,
    // PaginationComponent,
    // FacetComponent,
    // BreadcrumbComponent,
    // InfoComponent,
    // FacetNavComponent,
    // ResultCardComponent,
    // ActionsComponent,
    // SortSearchComponent,
    // ActionFieldSelectorComponent,
    // ResetComponent,
    // NumberFieldComponent,
    // CurrencyFieldComponent,
    SpyglassChatComponent,
    CommonModule,
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    // GalaxyChart
  ],
  providers: [
    ConfigService,
    MsalAccessService,
    OauthService,
    WebSocketService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, MsalAccessService, OauthService, WebSocketService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
})
export class SpyglassChatModule { }
