<form class="col form-group" [formGroup]="searchForm" (ngSubmit)="onSubmit()" style="height: auto; min-height: 80px;">
    <div class="row">
        <div class="col">
            <mat-form-field class="input" appearance="outline" style="width: 100%; margin-bottom: -3rem; margin-top: -1rem;">
                <input matInput class="mat-input" formControlName="query" [placeholder]="searchBarPlaceholder">
                <button mat-flat-button matSuffix color="primary" class="submit-button" type="submit" [disabled]="isSearchButtonDisabled || isLoading" [ngClass]="{'disabled-button': isSearchButtonDisabled, 'is-loading-button': isLoading}">
                    <span *ngIf="!isLoading">{{ searchButtonLabel }}</span>
                    <div *ngIf="isLoading" class="loading-gif" id="loading-indicator" ></div>
                </button>
            </mat-form-field>
        </div>
    </div>
</form>
