import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '../spyglass-search/config.service'
import { OauthService } from '../auth/oauth2-service'
import { throwError} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  error_message: string = '';
  is_oauth: boolean = false;
  forgotPasswordLink: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private oauthService: OauthService,
    private configService: ConfigService,
    private titleService: Title
  ) {
    this.titleService.setTitle('SWIRL AI Connect');
  }


  ngOnInit() {
    if(localStorage.getItem('isDark') === 'Dark')
      document.body.classList.toggle("dark-theme");
    this.configService.getConfig('default').subscribe(configResponse => {
      if(configResponse) {
          if(configResponse.oauthConfig && configResponse.oauthConfig?.clientId !== '<app-id>')
            this.is_oauth = true
          if(configResponse.swirlBaseURL)
            this.forgotPasswordLink = configResponse.swirlBaseURL + '/password_reset'
      }
    })
  }

  login() {
    this.error_message = ''

    this.authService.login(this.username, this.password).subscribe(
      (data: any) => {
        if(data.token) {
          this.authService.setToken(data.token)
          this.authService.setUser(data.user)
          if(data.chat_view) {
            this.authService.setChatStatus(data.chat_view == 'True')
          }
          if(data.ai_provider_exists) {
            this.authService.setAIProviderStatus(data.ai_provider_exists == 'True')
          }
          this.router.navigate(['/']);
        }
        else if(data.error) {
          console.log(data.error)
          this.error_message = 'Login Failed: Your user ID or password is incorrect'
        }
      },
      (error: any) => {
        if (error.status == "403") {
          this.router.navigate(["/lockout"]);
        } else {
          console.log(error);
          throwError(error);
        }
      }
    );
  }

  oidcLogin() {
    this.oauthService.initCodeFlow();
  }

}
