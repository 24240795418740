<div class="container-div">
    <a [href]="url" target="_blank" class="container-link">
        <ng-template [ngIf]="provider">
            <span class="authorship">{{provider}}</span>
        </ng-template>
        <ng-template [ngIf]="url">
            <a class="authorship-url">{{url}}</a>
        </ng-template>
    </a>
</div>
